<template>
  <v-container class="container" fluid fill-height>

    <v-flex v-if="loading" xs12>
      <v-layout align-center column>
        <v-progress-circular
          indeterminate
          color="white"
        ></v-progress-circular>
      </v-layout>
    </v-flex>

    <ProductNotFound v-if="!maintenance && !product && !loading" />
    <Maintenance v-if="maintenance" />

    <v-layout align-center class="justify-center" v-if="!maintenance && product && !loading">
      <v-flex xs12>
        <v-layout align-center column>
          <v-card class="card justify-center" elevation=10>
            <v-img 
              src="@/assets/logo_nrmemories.png"
              contain 
              height="80px"
              class="logo"
            />

            <p class="text-h5 text-center">
              {{ product.title }}
            </p>

            <v-stepper
              v-if="!paymentFinish"
              v-model="step"
              elevation=0
              flat
              alt-labels
            >
              <v-stepper-header style="box-shadow:none">
                <v-stepper-step
                  :complete="step > 1"
                  step="1"
                >
                  {{ product.vacation_season ? $t('season-label') : $t('event-label') }}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :complete="step > 2"
                  step="2"
                >
                  {{ product.vacation_season ? $t('student-label') : $t('traveler-label') }}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3">
                  Pagamento
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items class="step-items">
                <v-stepper-content style="padding:0" step="1">
                  <v-card class="stepper-card">
                    <v-form>
                      <v-row>
                        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
                          <v-select
                            :label="$t('unit-label')"
                            item-text="name"
                            item-value="id"
                            :items="units"
                            v-model="selectedUnit"
                            outlined
                          ></v-select>
                        </v-col>

                        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
                          <v-select
                            :label="$t('month-year-label')"
                            item-text="title"
                            item-value="id"
                            :items="monthYear"
                            v-model="selectedMonthYear"
                            outlined
                            :disabled="!selectedUnit"
                          ></v-select>
                        </v-col>

                        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
                          <v-select
                            :label="product.vacation_season ? 'Data da viagem' : $t('event-label')"
                            item-text="title"
                            item-value="id"
                            :items="events"
                            v-model="selectedEvent"
                            outlined
                            :disabled="!selectedMonthYear"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-form>

                    <v-divider v-if="selectedUnit && selectedEvent"></v-divider>

                    <v-row v-if="selectedUnit && selectedEvent">
                      <v-col cols="12">
                        <v-list-item>
                          <v-list-item-avatar v-if="!$vuetify.breakpoint.mobile">
                            <v-icon
                              class="blue lighten-3"
                              dark
                            >
                              mdi-camera
                            </v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content  v-if="!$vuetify.breakpoint.mobile">
                            <v-list-item-title>{{ product.title }}</v-list-item-title>

                            <v-list-item-subtitle>
                              {{ selectedUnitName }} - {{ selectedEventTitle }}
                            </v-list-item-subtitle>
                          </v-list-item-content>

                            R$ {{ convertPrice(eventPrice) }}

                          <v-spacer v-if="$vuetify.breakpoint.mobile"></v-spacer>

                            <v-btn
                              small
                              color="primary"
                              depressed
                              rounded
                              style="margin-left:20px"
                              @click="qtdOfPkgs = 1; step = 2"
                            >{{ $t('continue') }}</v-btn>

                        </v-list-item>
                      </v-col>
                    </v-row>

                    <v-divider v-if="selectedUnit && selectedEvent"></v-divider>

                    <v-row v-if="productPkgs.length > 0" style="padding: 3px; margin-top: 10px">
                      <v-col cols="12" class="text-h6 grey--text text--darken-3 text-center">
                        {{ product.vacation_season ? $t('number-of-students') : $t('number-of-travelers') }}
                      </v-col>

                      <v-col
                        v-for="(pkg, i) in productPkgs"
                        :key="i"
                      >
                        <v-card height="168px" outlined elevation=0>
                          <p class="text-center text-subtitle-2 text-label" style="padding-top:10px">
                            <span v-if="i < productPkgs.length -1">{{ $t('from') }}</span><span v-else>{{ $t('more-than') }}</span>
                            {{ pkg.qtd }}
                          </p>

                          <p class="text-center text-h6 blue--text text-label" style="line-height:18px">
                            R$ {{ convertPrice(pkg.price) }}<br/>
                            <span class="text-caption grey--text text--darken-4">
                              {{ $t('per-student') }}
                            </span>

                            <br/>

                            <span style="height:20px;" class="text-caption grey--text text--darken-1">
                              <span v-if="pkg.type == 'percent'">
                                ({{ pkg.value }}% {{ $t('of-discount') }})
                              </span>
                              <span v-else>
                                ({{ calculatePriceDiscount(pkg.value, eventPrice) }}% {{ $t('of-discount') }})
                              </span>
                            </span>
                          </p>

                          <p class="text-center">
                            <v-btn
                              small
                              color="primary"
                              depressed
                              rounded
                              @click="qtdOfPkgs = pkg.qtd; step = 2"
                            >{{ $t('continue') }}</v-btn>
                          </p>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row v-if="product.description && !selectedUnit">
                      <v-col>
                        <p v-html="toHtml(product.description)">
                        </p>
                      </v-col>                      
                    </v-row>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content style="padding:0" step="2">
                  <v-card class="stepper-card">
                    <v-row v-if="selectedUnit && selectedEvent" style="height:96px">
                      <v-col cols="7" v-if="!$vuetify.breakpoint.mobile">
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-icon
                              class="blue lighten-3"
                              dark
                            >
                              mdi-camera
                            </v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>{{ product.title }}</v-list-item-title>

                            <v-list-item-subtitle>
                              {{ selectedUnitName }} - {{ selectedEventTitle }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col :cols="$vuetify.breakpoint.mobile ? 6 : 3">
                        <v-select
                          :label="$t('number-of-travelers')"
                          :items="Array.from({length: 100}, (_, i) => i + 1)"
                          v-model="qtdOfPkgs"
                          outlined
                          dense
                          style="margin-top:10px"
                        ></v-select>
                      </v-col>

                      <v-col :cols="$vuetify.breakpoint.mobile ? 6 : 2">
                        <p class="blue--text text--darken-3 text-center" style="line-height:14px">
                          <v-spacer style="margin-top:18px" v-if="qtdOfPkgs == 1"></v-spacer>
                          <v-spacer style="margin-top:5px" v-if="qtdOfPkgs > 1 && calculatePriceDiscount(totalPrice, eventPrice * qtdOfPkgs) <= 0"></v-spacer>

                          R$ {{ convertPrice(totalPrice) }} 
                          <span class="text-caption grey--text text--darken-4" v-if="qtdOfPkgs > 1">
                            <br/>
                            {{ qtdOfPkgs }}x R$ {{ convertPrice(packagePrice) }}
                          </span>

                          <span class="text-caption grey--text text--darken-4" v-if="qtdOfPkgs > 1 && calculatePriceDiscount(totalPrice, eventPrice * qtdOfPkgs) > 0">
                            <br/>
                            ({{ calculatePriceDiscount(totalPrice, eventPrice * qtdOfPkgs) }}% {{ $t('discount-abbv') }})
                          </span>
                        </p>
                      </v-col>
                    </v-row>

                    <v-divider></v-divider>

                    <p style="padding-top:18px;padding-bottom:10px">
                      <strong>ATENÇÃO RESPONSÁVEL:</strong> a mensagem para cadastramento da foto do reconhecimento facial do {{ product.vacation_season ? $t('student-title') : $t('traveler-title') }} será enviada para o e-mail do responsável cadastrado. Utilize um e-mail válido. Caso seja você a pessoa que vai subir a selfie do responsável, coloque o seu e-email.
                    </p>

                    <v-form
                      ref="studentForm"
                      @submit.prevent="goToPayment"
                    >
                        <v-row
                        no-gutters
                          v-for="i in qtdOfPkgs"
                          :key="i"
                        >
                          <v-col
                            v-if="qtdOfPkgs > 1"
                            :cols="12"
                            class="text-subtitle-2"
                            style="margin-top:10px"
                          >
                            {{ $t('traveler-title') }} #{{ i }}
                          </v-col>

                          <v-col
                            :cols="!$vuetify.breakpoint.mobile ? 6 : 12"
                            style="padding: 2px"
                          >
                            <v-text-field
                              :label="product.vacation_season ? $t('student-name') : $t('traveler-name')"
                              :rules="rules.required"
                              :name="`student_name[${i}]`"
                              v-model="students[i]"
                              outlined
                            dense
                            ></v-text-field>
                          </v-col>

                          <v-col
                            :cols="!$vuetify.breakpoint.mobile ? 6 : 12"
                            style="padding: 2px"
                          >
                            <v-text-field
                              :label="$t('responsable-email')"
                              :rules="rules.email.concat(rules.required)"
                              :name="`student_email[${i}]`"
                              v-model="studentsEmails[i]"
                              outlined
                              dense
                            ></v-text-field>                            
                          </v-col>
                        </v-row>

                      <v-card-actions style="padding:0;margin-top:10px">
                        <v-btn
                          text
                          @click="step = 1"
                          :small="$vuetify.breakpoint.mobile"
                        >
                          {{ $t('back') }}
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-btn
                          type="submit"
                          color="primary"
                          :small="$vuetify.breakpoint.mobile"
                        >
                          {{ $t('continue') }}
                        </v-btn>
                      </v-card-actions>

                    </v-form>

                  </v-card>
                </v-stepper-content>

                <v-stepper-content style="padding:0" step="3">
                  <v-card class="stepper-card">

                    <v-row v-if="selectedUnit && selectedEvent" style="height:96px">
                      <v-col cols="6" v-if="!$vuetify.breakpoint.mobile">
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-icon
                              class="blue lighten-3"
                              dark
                            >
                              mdi-camera
                            </v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title>{{ product.title }}</v-list-item-title>

                            <v-list-item-subtitle>
                              {{ selectedUnitName }} - {{ selectedEventTitle }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col :cols="$vuetify.breakpoint.mobile ? 6 : 4">
                        <v-text-field
                          :label="product.vacation_season ? $t('number-of-students') : $t('number-of-travelers')"
                          v-model="qtdOfPkgs"
                          rounded
                          readonly
                          style="margin-top:12px"
                        ></v-text-field>
                      </v-col>

                      <v-col :cols="$vuetify.breakpoint.mobile ? 6 : 2">
                        <p class="blue--text text--darken-3 text-center" style="line-height:14px">
                          <v-spacer style="margin-top:26px" v-if="qtdOfPkgs == 1"></v-spacer>
                          <v-spacer style="margin-top:12px" v-if="qtdOfPkgs > 1"></v-spacer>

                          R$ {{ convertPrice(totalPrice) }} 
                          <span class="text-caption grey--text text--darken-4" v-if="qtdOfPkgs > 1">
                            <br/>
                            {{ qtdOfPkgs }}x R$ {{ convertPrice(packagePrice) }}
                          </span>
                        </p>
                      </v-col>
                    </v-row>

                    <v-form
                      ref="paymentForm"
                      @submit.prevent="pay()"
                    >  
                      <v-divider style="margin-bottom:10px"></v-divider>

                      <div class="text-h6" style="margin-bottom:10px">
                        {{ $t('customer-info-title') }}
                      </div>

                      <v-row>
                        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
                          <v-text-field
                            :disabled="toggleDisabledFields"
                            :label="$t('fullname')"
                            :rules="rules.required"
                            v-model="fullname"
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
                          <v-text-field
                            :disabled="toggleDisabledFields"
                            ref="fieldCpf"
                            :label="$t('cpf')"
                            :rules="rules.cpf"
                            v-model="cpf"
                            v-mask="'###.###.###-##'"
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 4">
                          <v-text-field
                            :disabled="toggleDisabledFields"
                            ref="fieldMobile"
                            :label="$t('mobile')"
                            :rules="rules.required"
                            v-model="mobile"
                            v-mask="'(##) #####-####'"
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
                          <v-text-field
                            :disabled="toggleDisabledFields"
                            :label="$t('email')"
                            :rules="rules.required.concat(rules.email)"
                            v-model="email"
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
                          <v-text-field
                            :disabled="toggleDisabledFields"
                            :label="$t('email-confirm')"
                            :rules="rules.required.concat(rules.email).concat(emailEqualCheck())"
                            v-model="emailCheck"
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>

                        <v-col cols="8" style="font-size:14px" v-if="!product.vacation_season">
                          Informe o Nome e Unidade da Escola/Grupo com a qual está realizando a sua viagem. (Ex: Colégio Nosso Recanto - São Paulo SP).
                        </v-col>

                        <v-col cols="8" style="margin-top:12px" v-if="product.vacation_season">
                          Temporada
                        </v-col>

                        <v-col cols="4">
                          <v-text-field
                            :disabled="toggleDisabledFields"
                            :label="product.vacation_season ? '' : 'Escola/Grupo'"
                            v-model="travellingGroup"
                            :rules="rules.required"
                            dense
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-divider style="margin-bottom:10px;margin-top:10px"></v-divider>

                      <v-row>
                        <v-col>
                          <div class="text-h6" style="margin-bottom:10px">
                            {{ $t('payment-information') }}
                          </div>
                        </v-col>
                        <v-col>
                          <v-tabs
                            v-model="paymentTab"
                            color="primary"
                            hide-slider
                            right
                          >
                            <v-tab>
                              {{ $t('payment-card') }}
                            </v-tab>
                            <v-tab>
                              {{ $t('payment-pix') }}
                            </v-tab>
                          </v-tabs>
                        </v-col>
                      </v-row>

                      <v-tabs-items v-model="paymentTab" style="margin-top:20px">
                        <v-tab-item>
                          <v-row v-if="paymentTab == 0">
                            <v-col cols="12" style="margin-top: 5px">
                              <v-text-field
                                :label="$t('card-fullname')"
                                :rules="rules.required"
                                v-model="cardFullname"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>

                            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 6">
                              <v-text-field
                                :label="$t('card-number')"
                                placeholder="•••• •••• •••• ••••"
                                v-mask="'#### #### #### ####'"
                                v-model="cardNumber"
                                :rules="rules.card"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>

                            <v-col :cols="$vuetify.breakpoint.mobile ? 6 : 3">
                              <v-text-field
                                :label="$t('exp-date')"
                                :placeholder="$t('card-date')"
                                v-mask="'##/##'"
                                v-model="expiration"
                                :rules="rules.expiration"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>

                            <v-col :cols="$vuetify.breakpoint.mobile ? 6 : 3">
                              <v-text-field
                                :label="$t('card-code')"
                                placeholder="•••"
                                v-mask="'###'"
                                v-model="cvc"
                                :rules="rules.cvc"
                                dense
                                outlined
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-tab-item>

                        <v-tab-item style="padding-bottom:30px">
                          <v-row v-if="!this.validCpf">
                            <v-col class="text-h6 text-center red--text">
                              {{ $t('cpf-alert') }}
                            </v-col>
                          </v-row>

                          <v-row v-if="this.validCpf && !pixData">
                            <v-col class="text-center">
                              <v-btn
                                :loading="loadingPix"
                                color="primary"
                                @click="generatePixCode"
                                outlined
                                x-large
                              >
                                {{ $t('generate-pix-code') }}
                              </v-btn>
                            </v-col>
                          </v-row>

                          <v-row v-if="this.validCpf && pixData">
                            <v-col cols="12">
                              <p>Tempo remanescente para efetuar o pagamento via PIX: <strong>{{ formatedSeconds }}</strong></p>
                            </v-col>
                            <v-col cols="8">
                              <v-textarea
                                outlined
                                class="hide-scrollbar"
                                :value="pixData.pixCopiaECola"
                                v-clipboard:copy="pixData.pixCopiaECola"
                                readonly
                              >
                                <template v-slot:append>
                                  <v-btn
                                    style="margin-top:40px;margin-left:13px"
                                    v-clipboard:copy="pixData.pixCopiaECola"
                                    icon
                                  >
                                    <v-icon>mdi-clipboard-text-outline</v-icon>
                                  </v-btn>
                                </template>
                              </v-textarea>
                            </v-col>
                            <v-col class="text-center">
                              <img :src="pixData.img" width="150" />
                            </v-col>
                          </v-row>
                        </v-tab-item>
                      </v-tabs-items>

                      <v-alert
                        v-if="errorAlert"
                        dense
                        type="error"
                      >
                        {{ errorMessage }}
                      </v-alert>

                      <v-card-actions style="margin-top:10px;padding:0">
                        <v-btn
                          text
                          @click="step = 2"
                          :small="$vuetify.breakpoint.mobile"
                        >
                          {{ $t('back') }}
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-btn
                          :loading="loadingPayment"
                          color="primary"
                          type="submit"
                          :small="$vuetify.breakpoint.mobile"
                        >
                          {{ $t('finish-payment') }}
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>

            <div class="text-center" v-if="paymentFinish" style="margin-top:80px">
              <v-icon size="160" color="green">mdi-checkbox-marked-circle</v-icon>

              <p class="text-h4" style="margin-top:40px">
                {{ $t('success-on-process-payment') }}
              </p>

              <p style="margin-top: 30px">
                Em sua fatura do cartão a compra do NR Memories irá constar como RAFEMA.
              </p>

              <p>
                Um e-mail de confirmação do NR Memories foi enviado para você e os acampantes cadastrados.
              </p>

              <p>
                Confira todas as informações! Se você não tiver recebido o email, por favor cheque seu SPAM.
              </p>

              <p>
                Caso não tenha recebido mesmo o email ou tenha alguma dúvida, nosso time de atendimento estará à disposição para ajudá-lo, entre em contato através do e-mail <a href="mailto:memories@nr.com.br">memories@nr.com.br</a>.
              </p>

              <br/>
              <v-btn outlined @click="resetPayment">{{ $t('back') }}</v-btn>
            </div>

          </v-card>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-snackbar v-model="showError">
      {{ $t('api-request-error') }}
    </v-snackbar>
  </v-container>
</template>

<style scoped>
  .card {
    width: 720px;
    min-height: 870px;
  }

  .stepper-card {
    box-shadow: none;
    padding-top: 5px;
  }

  .hide-scrollbar {
    overflow: hidden!important;
  }
</style>

<script>

import api from '@/services/api'
import i18n from '@/plugins/i18n'
import ProductNotFound from '@/components/ProductNotFound'
import Maintenance from '@/components/Maintenance'

export default {
  name: 'Product',

  components: {ProductNotFound, Maintenance},

  data: () => ({
    loading: true,
    product: null,
    packages: [],
    showError: false,
    step: 1,
    selectedUnit: null,
    selectedMonthYear: null,
    selectedEvent: null,
    qtdOfPkgs: 1,
    sendStudentEmails: false,
    students: [],
    studentsEmails: [],
    fullname: null,
    cpf: null,
    mobile: null,
    email: null,
    emailCheck: null,
    cardFullname: null,
    cardNumber: null,
    cvc: null,
    expiration: null,
    travellingGroup: null,
    makingPayment: false,
    travellingWithGroup: false,
    loadingPayment: false,
    errorAlert: false,
    errorMessage: null,
    paymentFinish: false,
    paymentTab: null,
    loadingPix: false,
    pixData: null,
    checkPixPaymentDaemon: null,
    countSeconds: 0,
    toggleDisabledFields: false,
    rules: {
      required: [
        v => !!v || i18n.t('form_rules.mandatory')
      ],
      email: [
        v => !v || /.+@.+\..+/.test(v) || i18n.t('form_rules.invalid_email')
      ],
      card: [
        v => /[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}/.test(v) || i18n.t('invalid-card-number')
      ],
      expiration: [
        v => /[0-9]{2}\/[0-9]{2}/.test(v) || i18n.t('invalid-exp-date')
      ],
      cvc: [
        v => /[0-9]{3}/.test(v) || i18n.t('invalid-cvv')
      ],
      cpf: [
        v => /[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}/.test(v) || i18n.t('invalid-cpf-number')
      ]
    }
  }),

  mounted() {
    this.getProduct()
    this.$recaptchaLoaded()
  },

  watch: {
    selectedUnit() {
      this.selectedEvent = null
    },

    step() {
      this.paymentTab = 0

      if (this.step == 2) {
        this.$refs.studentForm.resetValidation()
      }

      if (this.step == 3) {
        this.$refs.paymentForm.resetValidation()
      }
    },

    paymentTab() {
      if (this.paymentTab == 1 && this.$refs.paymentForm) {
        this.$refs.paymentForm.validate()
      } else {
        this.pixData = null
        this.loadingPayment = false
        this.toggleDisabledFields = false
        if (this.checkPixPaymentDaemon) {
          clearInterval(this.checkPixPaymentDaemon)
        }
      }
    }
  },

  computed: {
    maintenance() {
      return process.env.VUE_APP_MAINTENANCE_MODE == '1'
    },

    recaptchaSiteKey() {
      return process.env.VUE_APP_RECAPCHA_SITE_KEY
    },

    units() {
      return this.product.events
        .map(evt => evt.unit)
        .filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)
    },

    monthYear() {
      let events = this.product.events
        .filter(evt => evt.unit.id == this.selectedUnit)
        .map(evt => {
          evt.title = new Date(evt.start_at + "T00:00:00").toLocaleDateString('pt-BR') + ' - ' +
            new Date(evt.finish_at + "T00:00:00").toLocaleDateString('pt-BR')

            return evt
        })

      let monthYear = events
        .map(evt => evt.title.substring(3, 10))
        .concat(events
          .map(evt => evt.title.substring(16))
        )

      return [...new Set(monthYear)].sort()
    },

    events() {
      if (!this.selectedMonthYear) {
        return []
      }

      return this.product.events
        .filter(evt => evt.unit.id == this.selectedUnit)
        .map(evt => {
          evt.title = new Date(evt.start_at + "T00:00:00").toLocaleDateString('pt-BR') + ' - ' +
            new Date(evt.finish_at + "T00:00:00").toLocaleDateString('pt-BR')

            return evt
        })
        .filter(evt => evt.title.substring(3, 10) == this.selectedMonthYear || evt.title.substring(16) == this.selectedMonthYear)
    },

    selectedUnitName() {
      return this.units.find(unit => unit.id == this.selectedUnit).name
    },

    selectedEventTitle() {
      return this.events.length ? this.events.find(evt => evt.id == this.selectedEvent).title : null
    },

    eventPrice() {
      return this.events.find(evt => evt.id == this.selectedEvent)?.price || this.product.price || 0
    },

    productPkgs() {
      if (!this.selectedUnit || !this.selectedEvent || this.packages.length == 0) {
        return []
      }

      let pkgs  = []
      let price = this.eventPrice

      this.packages.forEach(pkg => {
        let pkgPrice = price > 0 ? pkg.value : 0

        if (price > 0 && pkg.type == 'percent' && pkg.value > 0) {
          pkgPrice = price - (price / 100 * pkg.value)
        }

        pkgs.push({
          id: pkg.id,
          qtd: pkg.qtd,
          price: pkgPrice,
          type: pkg.type,
          value: pkg.value
        })
      })

      return pkgs.sort((a, b) => a.qtd - b.qtd)
    },

    packagePrice() {
      let price = this.eventPrice
      let qtd = this.qtdOfPkgs

      if (this.productPkgs.length == 0) {
        return price
      }

      this.productPkgs.forEach((pkg => {
        if (qtd < pkg.qtd) {
          return
        }

        price = pkg.price
      }))

      return price
    },

    totalPrice() {
      return this.packagePrice * this.qtdOfPkgs
    },

    validCpf() {
      return this.paymentTab == 1
    },

    formatedSeconds() {
      return new Date(this.countSeconds * 1000).toISOString().substring(14, 19)
    }
  },

  methods: {
    getProduct() {
      let slug = this.$router.history.current.params.slug

      const productPromise = api.get(`public/product/${slug}`)
        .then((response) => {
          this.product = response.data
          document.title = this.product.title
        })
        .catch((error) => {
          if (error.response.status != 404) {
            this.showError = true
          }
        })

      const packagePromise = api.get(`public/product/${slug}/packages`)
        .then((response) => {
          this.packages = response.data.results[0]?.packages || []
        })
        .catch((error) => {
          if (error.response.status != 404) {
            this.showError = true
          }
        })

      Promise.all([productPromise, packagePromise])
        .finally(() => this.loading = false)
    },

    calculatePriceDiscount(value, price) {
      return Math.round(100 - (value * 100 / price))
    },

    goToPayment() {
      if (!this.$refs.studentForm.validate()) {
        return
      }

      this.step = 3
    },

    async pay() {
      if (!this.$refs.paymentForm.validate()) {
        return
      }

      const token = await this.$recaptcha('pay')

      let data = {
        email: this.email.trim(),
        fullname: this.fullname,
        cpf: this.cpf.replace(/\D/g,''),
        mobile: this.mobile,
        event_id: this.selectedEvent,
        students: [],
        creditcard: {
          fullname: this.cardFullname,
          number: this.cardNumber.replaceAll(' ', ''),
          cvv: this.cvc,
          exp_month: this.expiration.substring(0, 2),
          exp_year: this.expiration.substring(3)
        },
        group: this.travellingGroup,
        token: token
      }

      for (let i = 1; i <= this.qtdOfPkgs; i++) {
        data.students.push({fullname: this.students[i], email: this.studentsEmails[i].trim() || null})
      }

      this.loadingPayment = true
      this.errorAlert = false

      api.post('public/pay', data)
        .then((response) => {

          if (response.data.status == 'paid') {
            this.paymentFinish = true
          } else {
            this.errorAlert = true

            if (response.data.message) {
              this.errorMessage = response.data.message
            } else {
              this.errorMessage = 'Um erro ocorreu na tentativa de processar o pagamento.'
            }
          }

          this.loadingPayment = false
        })
        .catch((error) => {
          this.errorAlert = true
          if (error.response.data.message) {
            this.errorMessage = error.response.data.message
          } else {
            this.errorMessage = 'Um erro ocorreu na tentativa de processar o pagamento.'
          }

          this.loadingPayment = false
        })
    },

    emailEqualCheck() {
      if (this.email != this.emailCheck) {
        return this.$t('email-check-msg')
      }

      return true
    },

    resetPayment() {
      this.email = null
      this.emailCheck = null
      this.fullname = null
      this.cpf = null
      this.mobile = null
      this.cardFullname = null
      this.selectedEvent = null
      this.students = []
      this.studentsEmails = []
      this.cardNumber = null
      this.cvc = null
      this.expiration = null
      this.step = 1
      this.selectedUnit = null
      this.selectedMonthYear = null
      this.selectedEvent = null
      this.sendStudentEmails = false
      this.qtdOfPkgs = 1
      this.travellingGroup = null
      this.pixData = null
      this.paymentTab = 0
      this.toggleDisabledFields = false
      this.paymentFinish = false
    },

    convertPrice(price) {
      return price
        .toFixed(2)
        .toString()
        .replace('.', ',')
    },

    generatePixCode() {
      if (!this.$refs.paymentForm.validate()) {
        this.errorAlert = true
        this.errorMessage = 'É necessário informar os dados do responsável pelo pagamento para gerar o código PIX.'
        return
      }

      this.errorAlert = false
      this.errorMessage = null

      this.loadingPix = true

      let data = {
        email: this.email.trim(),
        fullname: this.fullname,
        cpf: this.cpf.replace(/\D/g,''),
        mobile: this.mobile,
        event_id: this.selectedEvent,
        students: [],
        group: this.travellingGroup
      }

      for (let i = 1; i <= this.qtdOfPkgs; i++) {
        data.students.push({fullname: this.students[i], email: this.studentsEmails[i].trim() || null})
      }

      api.post('public/pay/pix/create', data)
        .then((response) => {
          this.loadingPix = false
          this.pixData = response.data

          this.toggleDisabledFields = true

          this.startCheckPixPayment()
        })
        .catch(() => {
          this.loadingPix = false
        })
    },

    startCheckPixPayment() {
      this.loadingPayment = true
      this.countSeconds = 300
      this.errorAlert = false
      this.errorMessage = null

      this.checkPixPaymentDaemon = setInterval(() => {
        this.countSeconds-= 1
        if (this.countSeconds <= 0) {
          this.toggleDisabledFields = false
          this.pixData = null
          this.errorAlert = true
          this.errorMessage = 'Tempo para realização do pagamento via PIX expirado. Por favor, refaça a operação.'
          this.loadingPayment = false
          clearInterval(this.checkPixPaymentDaemon)
        }
        this.checkPaymentPixStatus()
      }, 1000)
    },

    checkPaymentPixStatus() {
      if (!this.pixData) {
        return
      }

      if (this.countSeconds % 17 == 0) {
        api.get('public/pay/pix/check/' + this.pixData.payment_id)
          .then((response) => {
            if (response.data.status == 'paid') {
              clearInterval(this.checkPixPaymentDaemon)
              this.paymentFinish = true
              this.loadingPayment = false
            }

            if (response.data.status == 'error') {
              this.loadingPayment = false
              this.toggleDisabledFields = false
              this.pixData = null
              this.errorAlert = true
              if (response.data.message) {
                this.errorMessage = response.data.message
              } else {
                this.errorMessage = 'Um erro ocorreu na tentativa de gerar o código PIX.'
              }

              this.loadingPayment = false

              this.toggleDisabledFields = false

              clearInterval(this.checkPixPaymentDaemon)
            }
          })
          .catch((error) => {
            this.errorAlert = true
            if (error.response.data.message) {
              this.errorMessage = error.response.data.message
            } else {
              this.errorMessage = 'Um erro ocorreu na tentativa de processar o pagamento.'
            }

            this.loadingPayment = false

            this.toggleDisabledFields = false

            clearInterval(this.checkPixPaymentDaemon)
          })
      }
    },

    toHtml(txt) {
      return txt.replaceAll("\n", "<br/>")
    }
  }
};
</script>
