<template>
  <v-layout align-center class="justify-center">
    <v-flex xs12>
      <v-layout align-center column>
        <v-card class="card justify-center" style="width: 500px">
          <v-img 
            src="@/assets/logo_nrmemories.png"
            contain 
            height="120px"
            class="logo"
          />

          <p class="">
            Olá!
            Estamos realizando uma manutenção programada em nosso sistema para garantir ainda mais segurança na sua experiência.
<br/><br/>
            Se precisar de ajuda com a compra do NR Memories ou tiver qualquer dúvida, fale com a gente pelos nossos canais de atendimento:
<br/><br/>
            📱 WhatsApp: (11) 5090-7419<br/>
            📧 E-mail: memories@nr.com.br
<br/><br/>
            Estamos aqui para te ajudar!
          </p>
        </v-card>
      </v-layout>
     </v-flex>
  </v-layout>
</template>

<script>

export default {
  name: 'ProductNotFound',

  data: () => ({
    //
  }),
};
</script>
