import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import VueMask from 'v-mask'
import VueClipboard from 'vue-clipboard2'
import { VueReCaptcha } from 'vue-recaptcha-v3';

Vue.config.productionTip = false

Vue.use(VueMask)
Vue.use(VueClipboard)

Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPCHA_SITE_KEY
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
